<template>
  <div>
    <div>
      <section class="w-100">
        <section>
          <h5 class="mb-0">Selecione até 5 clientes para enviar a pesquisa de satisfação</h5>
          <span>{{ selecteds.length }} selecionados</span>
        </section>

        <div class="input-search w-100 p-0 my-2">
          <b-form-input
            @input="handleSearch"
            v-model="search"
            placeholder="Digite para pesquisar"
            class="mb-2"
          />
          <b-button
            variant="light"
            class="px-2 d-flex align-items-center absolute icon-action"
          >
            <b-icon icon="search"></b-icon>
          </b-button>
        </div>
      </section>
      <cards-list
        grid="2"
        responsive="1"
        gap="12"
        class="content-security-modal"
      >
        <b-card
          no-body
          @click="handleSelect(client)"
          class="mb-3 pointer card-security p-3 mx-1"
          :class="[
            selecteds.includes(client) && 'selected',
            selecteds.length === 5 && !selecteds.includes(client) && 'disabled',
          ]"
          v-for="(client, index) in items"
          :key="index"
        >
          <span
            class="ball-selection"
            :class="selecteds.includes(client) && 'selected'"
          />
          <div class="mr-md-4" style="min-height:50px">
            <span class="d-block title">
              {{ client.social_reason }}
            </span>
            <small class="text-muted">{{ client.situation }}</small>
          </div>
          <div>
            <table class="mb-3">
              <tr>
                <td><strong>CNPJ:</strong></td>
                <td class="pl-2">
                  {{ client.cnpj | VMask("##.###.###/####-##") }}
                </td>
              </tr>
              <tr>
                <td><strong>Email:</strong></td>
                <td class="pl-2" style="vertical-align: top;">
                  {{ client.email }}
                </td>
              </tr>
            </table>
          </div>

          <small class="text-danger" v-if="index === 2">
            Não elegível para assinar o atestado de capacidade técnica
          </small>
          <small class="text-muted" v-if="index !== 2">
            Elegível para assinar o atestado de capacidade técnica
          </small>
        </b-card>
      </cards-list>
      <!--
      <div class="d-flex">
        <b-icon-plus variant="danger" />
        <span>
          Clientes não elegíveis para assinar o atestado são aqueles que o
          e-mail de cadastro na <strong>Maat</strong> é diferente do e-mail de
          cadastro na Receita Federal.
          <router-link
            class="text-primary"
            to="/my-enterprise/clients"
            target="_blank"
          >
            Acesse aqui seus clientes.
          </router-link>
        </span>
      </div>
      -->
      <div class="d-flex justify-content-end w-100">
        <b-button
          variant="primary"
          @click="sendSelecteds()"
          :disabled="selecteds.length === 0"
        >
          Salvar seleção
        </b-button>
      </div>
    </div>
    <!-- <div v-else class="text-center">
      <img
        src="@/assets/images/skills.png"
        class="d-block mx-auto mb-3"
        height="100"
      />
      <p>Não há clientes cadastrados para seleção</p>
      <router-link to="/my-enterprise/clients">
        <b-button>Adicionar clientes</b-button>
      </router-link>
    </div> -->
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [listPaginated],
  data() {
    return {
      action_name: "provider_clients",
      menus: [],
      params: {},
      empty: true,
      selecteds: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user"]),
  },
  methods: {
    ...mapActions(["get_all_provider_clients"]),
    sendSelecteds() {
      this.$emit("close", this.selecteds);
    },
    handleSelect(sk) {
      if (this.selecteds.includes(sk)) {
        this.selecteds = this.selecteds.filter((item) => item !== sk);
        return;
      }
      if (this.selecteds.length === 5) {
        return;
      }
      this.selecteds.push(sk);
    },
  },
};
</script>
<style lang="scss">
.content-security-modal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  .card-security {
    &.disabled {
      opacity: 0.4;
      filter: grayscale(5);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }
    &.selected {
      border-color: $primary;
      .title {
        color: $primary;
      }
    }
  }
}
</style>
